import React, { useRef } from 'react'; // Import useRef from React
import './styles/LandingPage.css'; // Import CSS for styling
import NewsletterCard from './NewsletterCard'; // Import your NewsletterCard component
import Footer from './Footer'; // Import your Footer component
import { Link } from 'react-router-dom';

const LandingPage = () => {
  const scrollController = useRef(null); // Create a reference for scrolling

  return (
    <div className="landing-page">
      <main className="content">
        <div className="title-container">
        <h1 className="main-title">
          friends and money<br /> no one can take away
        </h1>
        </div>
        <div className="button-row">
          <Link to="/download"><button className="button primary">Join Waitlist</button></Link>
          <Link to="/welcome-to-orange"><button className="button secondary">Learn More</button></Link>
        </div>
        <picture>
          <img
            src='/assets/images/three-phone.png'
            alt="Three Phones"
            className="three-phone"
          />
        </picture>
        <div className="separator, top-separator" />
        {/* USP Section 1 */}
        <div className="usp-container">
          <img src='/assets/images/bitcoin.png' alt="Bitcoin" className="usp-image" />
          <div className="usp-text">
            <div className="usp-header">
              <img src='/assets/icons/bitcoin.svg' width={48} height={48} alt="Bitcoin Icon" />
              <h2 className="bitcoin-title">Bitcoin</h2>
            </div>
            <h2 className="experience-money">Experience money that’s easy to own</h2>
            <p className="bitcoin-description">
              Our Bitcoin wallet allows you to securely store and manage your Bitcoin, making it simple to send and receive funds whenever you need.
            </p>
            <Link to="/download"><button className="button primary">Send Now</button></Link>
          </div>
        </div>

        <div className="separator" />

        {/* USP Section 2 */}
        <div className="usp-container">
          <img src='/assets/images/message-exchange.png' alt="Messages" className="usp-image" />
          <div className="usp-text">
            <div className="usp-header">
              <img src='/assets/icons/message.svg' width={48} height={48} alt="Message Icon" />
              <h2 className="bitcoin-title">Messages</h2>
            </div>
            <h2 className="experience-money">Talk to your friends without the snooping</h2>
            <p className="bitcoin-description">
              Our messaging provides secure and encrypted communication, allowing you to chat with others while keeping your conversations confidential.
            </p>
            <Link to="/download"><button className="button primary">Message Now</button></Link>
          </div>
        </div>

        <div className="separator" />

        {/* USP Section 3 */}
        <div className="usp-container">
          <img src='/assets/images/social.png' alt="Chat Rooms" className="usp-image" />
          <div className="usp-text">
            <div className="usp-header">
              <img src='/assets/icons/door.svg' width={48} height={48} alt="Rooms Icon" />
              <h2 className="bitcoin-title">Chat Rooms</h2>
            </div>
            <h2 className="experience-money">Share your ideas and learn with the world</h2>
            <p className="bitcoin-description">
              Our Web5-based chat rooms provide users with a platform for connecting, chatting, and sharing content in real-time with friends and groups.
            </p>
            <Link to="/download"><button className="button primary">Chat Now</button></Link>
          </div>
        </div>

        <div className="separator" />
        
        {/* Newsletter Card Section */}
        <NewsletterCard scrollController={scrollController} />
        {/* Download Section */}
        <div className="separator" />

        <h2 className="download-title, text-download">
            <span style={{ color: 'white', fontWeight: '700', fontSize: '42px' }}>download </span>
            <img src='/assets/icons/textmark.svg' alt="Logo" className="textmark text-download" />
        </h2>
        
        <div className="download-cards">
          <div className="download-card">
            <img src='/assets/icons/app-store.svg' className="download-icon" alt="iOS Icon" />
            <h3 className="download-card-title">iOS.</h3>
            <p className="download-card-description">Get the mobile app from the App Store</p>
            <Link to="/download"><button className="button secondary download-button">Download iOS</button></Link>
          </div>
          <div className="download-card">
            <img src='/assets/icons/google-play.svg' className="download-icon" alt="Android Icon" />
            <h3 className="download-card-title">Android.</h3>
            <p className="download-card-description">Get the mobile app from Google Play</p>
            <Link to="/download"><button className="button secondary download-button">Download Android</button></Link>
          </div>
          <div className="download-card">
            <img src='/assets/icons/monitor.svg' className="download-icon" alt="Desktop Icon" />
            <h3 className="download-card-title">Desktop.</h3>
            <p className="download-card-description">Get the desktop app from us</p>
            <Link to="/download"><button className="button secondary download-button">Download Desktop</button></Link>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default LandingPage;
