import React from 'react';
import './styles/Footer.css'; // Import CSS for styling
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="separator" />
      <div className="footer-links">
        <Link to="/blogs">
          <button className="button ghost footer">Blog</button>
        </Link>
        <Link to="/contact-us">
          <button className="button ghost footer">Contact Us</button>
        </Link>
        <Link to="/privacy-policy">
          <button className="button ghost footer">Privacy Policy</button>
        </Link>
      </div>
      <div className="social-icons">
        <a href="https://www.instagram.com/orangeme_app" target="_blank" rel="noopener noreferrer">
          <img src="/assets/icons/instagram.svg" alt="Instagram" width={48} height={48} />
        </a>
        <a href="https://www.x.com/orangeme_app" target="_blank" rel="noopener noreferrer">
          <img src="/assets/icons/x-twitter.svg" alt="Twitter" width={48} height={48} />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61567110598209" target="_blank" rel="noopener noreferrer">
          <img src="/assets/icons/facebook.svg" alt="Facebook" width={48} height={48} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
