import React from 'react';
import './styles/Download.css'; // Import the CSS file for styling
import NewsletterCard from './NewsletterCard'; // Import your NewsletterCard component
import Footer from './Footer'; // Import your Footer component

const Download = () => {
    return (
        <div className="download-page">
            <body className="download-content">
                <NewsletterCard />
            </body>
            <Footer />
        </div>
    );
};

export default Download;
