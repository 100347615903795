import React from 'react';
import './styles/ContactUs.css'; // Import the CSS file for styling
import Footer from './Footer'; // Import your Footer component

const ContactUs = () => {
    return (
        <div className="contact-page">
            <h2 className="coming-soon">Contact Us.</h2>
            <body className="contact-content">
                <p className="contact">We’d love to hear from you! Whether you have questions, feedback, or just want to say hello, feel free to reach out.</p>
                <p className="info">hello@orange.me</p>
                <p className="info">(406) 802-2162</p>
            </body>
            <Footer />
        </div>
    );
};

export default ContactUs;
