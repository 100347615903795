import React from 'react';
import './styles/Header.css'; // Import CSS for styling
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="header">
      <Link to="/">
        {/* Display logomark.svg for larger screens */}
        <img src="/assets/icons/logomark.svg" alt="Logo" className="logo logo-large" />
      </Link>
      <div className="button-row">
        <Link to="/blogs"><button className="button ghost">Blog</button></Link>
        <Link to="/download"><button className="button primary">Join Waitlist</button></Link>
      </div>
    </header>
  );
};

export default Header;
