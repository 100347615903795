import React from 'react';
import './styles/PrivacyPolicy.css'; // Import the CSS file for styling
import Footer from './Footer'; // Import your Footer component

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-page">
            <main className="privacy-policy-content">
                
                <h2 className="privacy-policy-title">Privacy Policy</h2>
                <p className="privacy-policy-text">
                    The orange.me app does not collect or share any information with anyone. <br/>
                    <br/> Orange Me LLC does not operate any services or servers to support the orange.me app.
                </p>
            </main>
            
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
