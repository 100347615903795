import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import Download from './Download'; 
import PrivacyPolicy from './PrivacyPolicy'; // Import the PrivacyPolicy component
import Blogs from './Blogs';
import WelcomeToOrange from './blogs/welcometoorange';
import WhatIsWebFive from './blogs/whatisweb';
import Header from './Header';
import ScrollToTop from './ScrollToTop';
import ContactUs from './ContactUs';

const App = () => {
    return (
        <Router>
            <Header />
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/download" element={<Download />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/what-is-web5" element={<WhatIsWebFive />} /> 
                <Route path="/welcome-to-orange" element={<WelcomeToOrange />} />
                <Route path="/contact-us" element={<ContactUs />} />
            </Routes>
        </Router>
    );
};

export default App;
