import React, { useRef } from 'react'; // Import useRef from React
import './blog.css'; // Import the CSS file for styling
import Footer from '../Footer'; // Import your Footer component
import NewsletterCard from '../NewsletterCard'; // Import your NewsletterCard component

const WelcomeToOrange = () => {
    const scrollController = useRef(null); // Create a reference for scrolling
    return (
        <div className="blog single">
            <body className="blog-content">
                <img src="/assets/images/WelcomeToOrange.png" alt="blog-title-image" className="blog-image"/>
                <p className="post-date blog">October 4th, 2024</p>
                <p className="post-title blog">
                    Welcome to <span style={{ color: "#EB343A" }}>orange</span>
                </p>
                <p className="post-excerpt blog">An app 65 million years in the making</p>
                <p className="para">
                    Digital products make sending money and talking to friends online easy. 
                    While we have more access to communications and financial services than ever, 
                    we’re accessing them through an ever-decreasing number of digital platforms. 
                </p>
                <p className="para">
                    Thanks to advances in technology, 
                    this trade-off between digital services and digital privacy is no longer necessary. 
                    That’s why we built orange, an app built to chat with friends and send money online, 
                    entirely <span class='orange'>peer-to-peer (P2P)</span>, in a beautiful, easy-to-use design.
                </p>
                <p className="para">
                    Emerging technologies like Bitcoin and Web5 make it possible for the first time 
                    to send money and talk to friends online, entirely <span class='orange'>P2P</span>, 
                    with no centralized digital services. It’s time to take our digital 
                    rights back in our hands, and we’re building orange to be the best digital product to do it.
                </p>
                <div className="separator" />
                {/* Money no one can take away */}
                <h1 className="sect-title">Money no one can take away</h1>
                <p className="para">
                    Bitcoin is a digital currency you can send and spend like cash. Unlike cash, 
                    which relies on networks between financial institutions, bitcoin is entirely <span class='orange'>P2P</span>. 
                </p>
                <p className="para">
                    The decentralized nature of bitcoin makes it the first digital money you 
                    can manage and spend with no bank account, or privacy trade-off, required.
                </p>
                <p className="para">
                    Bitcoin is P2P money anyone can use. But the experience of actually using today’s bitcoin wallets 
                    can feel unfamiliar for new users, and many people use centralized “digital wallets” for exactly this reason.
                </p>
                <p className="para">
                    orange’s bitcoin wallet brings the modern, easy-to-use experience of digital wallets to bitcoin. 
                </p>
                <p className="para">
                    By offering a wallet that feels intuitive and familiar to first time bitcoin users, 
                    orange can help bring the world’s first and best P2P money to everyone.
                </p>
                <div className="separator" />
                {/* Friends no one can take away */}
                <h1 className="sect-title">Friends no one can take away</h1>
                <p className="para">
                    Messaging apps like Signal and Telegram are the fastest and easiest way to talk to friends online. 
                    These apps have revolutionized how we stay in touch by bringing secure, 
                    end-to-end encrypted (E2E) messaging to everyone.
                </p>
                <p className="para">
                    While our conversations are secured E2E, their meta data is not, 
                    and privacy-compromising information like contact lists and communication 
                    records are still routed through these digital services.
                </p>
                <p className="para">
                    Messaging on orange builds on the experience and security of today’s E2E apps, 
                    using Web5 technology to build the first messaging app that’s also entirely <span class='orange'>P2P</span>. 
                    With orange, everyone is finally free to talk with just their friends.
                </p>
                <div className="separator" />
                 {/* The road ahead */}
                 <h1 className="sect-title">The road ahead</h1>
                <p className="para">
                    orange is currently under development and will be available soon. 
                    Sign up for our waitlist to stay up to speed on development and join 
                    us in taking our digital privacy back in our own hands. The future is <span class='orange'>P2P</span>. 
                </p>
                <div className="separator" />
                {/* Newsletter Card Section */}
                <NewsletterCard scrollController={scrollController} />
            </body>
            <Footer />
        </div>
    );
};

export default WelcomeToOrange;
