import React, { useEffect, useState } from 'react';
import './styles/TextInput.css'; // Import any necessary styles

const TextInput = ({
  hint,
  title,
  onChanged,
  onEditingComplete,
  onSubmitted,
  showIcon = false,
  error = '',
  presetTxt = '',
  height,
  focusNode,
}) => {
  const [value, setValue] = useState(presetTxt);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (focusNode) {
      focusNode.current = {
        onFocus: () => setIsFocused(true),
        onBlur: () => setIsFocused(false),
      };
    }
  }, [focusNode]);

  const handleFocus = () => {
    setIsFocused(true); // Set focused state
  };

  const handleBlur = () => {
    setIsFocused(false); // Reset focused state
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    onChanged && onChanged(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmitted && onSubmitted(value);
    }
  };

  return (
    <div className="text-input-container" style={{}}>
      {title && <label className="text-input-title">{title}</label>}
      <div
        className="text-input-wrapper"
        style={{
          borderColor: isFocused ? '#8A8C93' : '#8A8C93', // Keep the same color on focus
        }}
      >
        <input
          type="text"
          value={value}
          placeholder={hint}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          className="text-input"
        />
        {showIcon && <button className="send-button">Send</button>}
      </div>
    </div>
  );
};

export default TextInput;
