// src/Blogs.js
import React from 'react';
import './styles/Blogs.css'; // Import the CSS file for styling
import Footer from './Footer'; // Import your Footer component
import { Link } from 'react-router-dom'

const Blogs = () => {
    const blogPosts = [
        {
            id: 1,
            title: "What is Web5?",
            excerpt: "Understanding the internet of the future.",
            date: "October 4th, 2024",
            image: "/assets/images/Web5.png", // Path to the image for this blog post
            path: "/what-is-web5"
        },
    ];

    const promotedBlog = {
        title: "Welcome to orange",
        excerpt: "An app 65 million years in the making.",
        date: "October 4th, 2024",
        image: "/assets/images/WelcomeToOrange.png", // Replace with the actual path to your image
        path: "/welcome-to-orange"
    };

    return (
        <div className="blog-page">
            <main className="blog-content">
                <div className="promoted-blog">
                    <Link to={promotedBlog.path} className="link">
                        <img src={promotedBlog.image} alt={promotedBlog.title} className="promoted-image"/>
                        <div className="promoted-info">
                            <p className="post-date">{promotedBlog.date}</p>
                            <h3 className="post-title promoted-info">{promotedBlog.title}</h3>
                            <p className="post-excerpt promoted-info">{promotedBlog.excerpt}</p>
                            <button className="button ghost blog">Read More</button>
                        </div>
                    </Link>
                </div>
                <h3 className="most-recent">Most Recent</h3> {/* Add this line */}
                <div className="underline" />
                <div className="blog-posts">
                    {blogPosts.map(post => (
                        <div key={post.id} className="blog-post">
                            <Link to={post.path} className="link">
                                <img src={post.image} alt={post.title} className="post-image" />
                                <h3 className="post-title">{post.title}</h3>
                                <p className="post-date">{post.date}</p>
                                <p className="post-excerpt">{post.excerpt}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Blogs;
