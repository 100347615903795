import React from 'react'; // Import useRef from React
import './blog.css'; // Import the CSS file for styling
import Footer from '../Footer'; // Import your Footer component

const WhatIsWebFive = () => {
    return (
        <div className="blog">
            <body className="blog-content">
                <img src="/assets/images/Web5.png" alt="blog-title-image" className="blog-image"/>
                <p className="post-date blog">October 4th, 2024</p>
                <p className="post-title blog">
                    What is Web5?
                </p>
                <p className="post-excerpt blog">Understanding The Internet of the Future.</p>
                <p className="para">
                    <span class='green'>Web5</span> is an upgrade to internet infrastructure that enables app developers to 
                    create user-friendly apps, giving users complete control over their own data, accounts, 
                    reputation, friends list, social media posts, and communication. 
                </p>
                <p className="para">This is achieved in a reasonably secure and private way.</p>
                <p className="para">Web5 consists of three components; DIDs, VCs, and DWNs.</p>
                <div className="separator" />
                {/* Decentralized Identifiers (DIDs) */}
                <h1 className="sect-title">Decentralized Identifiers (DIDs)</h1>
                <h1 className="sect-title m">What Are DIDs?</h1>
                <p className="para">
                    A <span class='green'>DID</span>, or <span class='green'>Decentralized Identifier</span>, is a user account that no one can take away from you. 
                    It works on any website, giving you control over your digital identity across different platforms.
                </p>
                <h1 className="sect-title m">How Are DIDs New?</h1>
                <p className="para">
                    Before Bitcoin and BitTorrent, user accounts were controlled by companies. 
                    If a company decided to disable your account, they could cut off your connections with friends, 
                    family, coworkers, or colleagues at any time, without facing any consequences. 
                    This could happen accidentally, or intentionally to hurt people the company didn’t like.
                </p>
                <p className="para">With <span class='green'>DIDs</span>, this is prevented for the first time ever.</p>
                <h1 className="sect-title m">Why Do DIDs Matter?</h1>
                <p className="para">
                    Not long ago, all your information and relationships were based on physical contact. 
                    You met people at school or grew up in the same town, and the only way to stop you from 
                    sharing information or connecting with others was through imprisonment. 
                </p>
                <p className="para">
                    Society developed safeguards like trial by jury and national constitutions to protect these freedoms.
                     However, today, most of our connections and interactions happen online. 
                </p>
                <p className="para">
                    Technology has advanced too quickly, and legal and political safeguards haven't been able to keep up. 
                     <span class='green'> DIDs</span> offer a solution by ensuring that companies cannot abuse their power and disable your account.
                </p>
                <div className="separator" />
                {/* Verifiable Credentials (VCs) */}
                <h1 className="sect-title">Verifiable Credentials (VCs)</h1>
                <h1 className="sect-title m">What Are Verifiable Credentials?</h1>
                <p className="para">
                    <span class='green'>
                    Verifiable Credentials (VCs)</span> allow individuals to build a reputation and reveal information about themselves, 
                    under their full control. When someone trustworthy issues a <span class='green'>Verifable Credential</span> 
                    that includes your <span class='green'>DID</span>, it certifies specific information about you. 
                </p>
                <p className="para">
                    For example, a university can issue a VC confirming your graduation, similar to a paper diploma. 
                    Just like with a physical diploma, you control when to share this information because you possess the VC.
                     Anyone viewing the <span class='green'>Verifiable Credential</span> can verify its authenticity, 
                     that it has not been tampered with, and that it was issued by the university.
                </p>
                <p className="para">
                    Similarly, companies can issue <span class='green'>VCs</span> to confirm employment details, such as job title and employment duration, 
                    while nonprofits can issue VCs to verify donations or volunteer hours. Once you have a  
                    <span class='green'> Verifiable Credential</span>, the issuing entity cannot ever revoke or take it away from you.
                </p>
                <h1 className="sect-title m">How Are Verifiable Credentials Innovative?</h1>
                <p className="para">
                    This is the first time accounts are controlled by the account owner rather than the company running the website. 
                </p>
                <p className="para">
                    For the first time, we can take possession of our own reputation and documents, 
                    keeping them secure and sharing them with whomever we choose, 
                    all without needing anyone's permission.
                </p>
                <h1 className="sect-title m">Why Do Verifable Credentials Matter?</h1>
                <p className="para">
                    Being in control of your own reputation has been a goal on the internet for 50 years.
                     When you can demonstrate your trustworthiness to a fellow man without needing permission 
                     from a corporation, it enables you to build a reputation similar to how it’s developed in the physical world. 
                </p>
                <p className="para">
                    You can become known as someone who pays their debts, is pleasant to work with, and treats others with respect. 
                    This reputation can be more valuable than money, allowing you to have very valuable opportunities 
                    meeting smart people or getting important jobs.
                </p>
                <p className="para">
                    If a single corporation or a small group of corporations controls your reputation and can erase it with a keystroke, 
                    it poses a threat as serious as having control over your money. Both scenarios undermine your autonomy.
                </p>
                <div className="separator" />
                {/* Decentralized Web Nodes (DWNs) */}
                <h1 className="sect-title">Decentralized Web Nodes (DWNs)</h1>
                <h1 className="sect-title m">What Are DWNs?</h1>
                <p className="para">
                    <span class='green'> DWNs (Decentralized Web Nodes)</span> allow users to store their data in the cloud, hosted by multiple companies. 
                    This duplication ensures that if any one company deletes data, it is still preserved elsewhere and will not be lost. 
                    <span class='green'> DWNs</span> are designed to maintain user privacy, so companies cannot see who is storing data or accessing it.
                </p>
                <p className="para">
                    Companies that host a <span class='green'>DWN</span> receive a small payment in Bitcoin for storing a file. 
                    They cannot determine how files are related or who is reading or writing them, ensuring a high 
                    level of confidentiality and security for users.
                </p>
                <h1 className="sect-title m">How Are DWNs New?</h1>
                <p className="para">
                    Before <span class='green'>DWNs</span>, you had two main choices for data storage: you could use a company like Dropbox, 
                    which meant giving them access to your personal information, or you could set up a home server, 
                    requiring a significant upfront investment and technical knowledge, along with hours of maintenance each year.
                </p>
                <p className="para">
                    When using applications, you could store data locally on your laptop, like with Microsoft Word, or in a company’s database,
                     like Google Docs. However, storing data in a company's database allowed them to read every word you wrote, including your private thoughts.
                </p>
                <h1 className="sect-title m">Why Do DWNs Matter?</h1>
                <p className="para">
                    For the first time, <span class='green'>DWNs</span> enable ordinary people to access their files on their phones or laptops 
                    without having to give up excessive personal information to a corporation.
                </p>
                <p className="para">
                    A <span class='green'>DWN</span> makes cloud storage as safe and private as your phone or laptop’s internal hard drive.
                </p>
                <div className="separator" />
                {/* CTfreakingA */}
                <p className="para">
                <   span class='green'>Web5</span> marks an important shift in internet infrastructure, giving users greater control over their digital lives. 
                </p>
                <p className="para">
                    By integrating <span class='green'>Decentralized Identifiers (DIDs)</span>, <span class='green'>Verifiable Credentials (VCs)</span>, and <span class='green'>Decentralized Web Nodes (DWNs)</span>, this upgrade provides a solid framework for app developers to create user-friendly applications. 
                </p>
                <p className="para">
                    The future lies in <span class='green'>Web5</span>
                </p>
            </body>
            <Footer />
        </div>
    );
};

export default WhatIsWebFive;
