import React, { useState } from 'react';
import TextInput from './TextInput'; // Import the TextInput component
import './styles/NewsletterCard.css'; // Import CSS for styling

const NewsletterCard = ({ scrollController }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const handleEmailChange = (input) => {
    setEmail(input);
    setIsDisabled(input === '' || !/\S+@\S+\.\S+/.test(input));
  };

  const handleSubscribe = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://orange.me/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer pat-na1-f667645c-a00b-4b2f-a384-0bc0ef464efb',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setResponseMessage('Subscription successful');
      } else if (response.status === 409) {
        setResponseMessage('Email already added');
      } else {
        setResponseMessage('Subscription failed');
      }
    } catch (error) {
      setResponseMessage('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="newsletter-card" style={{ backgroundColor: 'black', padding: '24px', borderRadius: '12px' }}>
      <div style={{ fontWeight: 'bold', fontSize: '32px', color: 'white' }}>Get notified.</div>
        <p style={{ color: 'white', fontSize: '20px' }}>
         Join our waitlist to receive an email notification as soon as <span> <img src='/assets/icons/textmark.svg' alt="Logo" className="textmark lg" /> </span> is launched!
        </p>
      <TextInput
        hint="Your email address..."
        title="Email Address"
        onChanged={handleEmailChange}
        error={isDisabled ? 'Invalid email' : ''}
      />
      {isLoading ? (
        <div className="loader">Loading...</div>
      ) : (
        <button
          className={`button newsletter primary ${isDisabled ? 'disabled' : ''}`}
          onClick={handleSubscribe}
          disabled={isDisabled}
          style={{
            color: isDisabled ? '#000' : '#fff',
            backgroundColor: isDisabled ? '#8A8C93' : '#EB343A',
          }}
        >
          Join Waitlist
        </button>
      )}
      {responseMessage && <p style={{ color: 'white' }}>{responseMessage}</p>}
    </div>
  );
};

export default NewsletterCard;
